
<script>
import VueMasonryWall from "vue-masonry-wall";
import Client from "@/services/Client";
import VLoader from "@/components/VLoader";
import Modal from "@/components/Modal";

export default {
    name: "VPhotos",
    props: {
        album: {},
    },
    components: {
        VueMasonryWall,
        VLoader,
        Modal,
    },
    data() {
        return {
            loading: true,
            showPhoto: false,
            context: null,
            photos: [],
            options: {
                width: 300,
                padding: {
                    1: 4,
                    default: 12,
                },
            },
        };
    },
    async created() {
        await this.fetchAlbum(this.album);
        this.loading = false;
    },
    methods: {
        async fetchAlbum(album) {
            try {
                const r = await Client.GET(`/albums/${album}/images.json`);
                this.photos = r.data;
            } catch (e) {}
        },
        async append() {
            return [];
        },
        openModal(img) {
            this.showPhoto = true;
            this.context = img;
        },
    },
};
</script>

<template>
    <div class="mt-4">
        <v-loader :loading="loading" v-if="loading" />

        <vue-masonry-wall
            v-else-if="photos.length > 0"
            :items="photos"
            :options="options"
            @append="append"
        >
            <template v-slot:default="{ item }">
                <div class="em">
                    <img :src="item.image" v-img="{ src: item.imageFull }" />
                </div>
            </template>
        </vue-masonry-wall>
        <portal to="modals">
            <modal v-if="showPhoto" @close="showPhoto = false" :show="true">
                <img :src="context" class="" />
            </modal>
        </portal>
    </div>
</template>

<style>
</style>
